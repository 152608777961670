import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  useTheme,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import DeleteIcon from "@mui/icons-material/Delete";
import Header from "../../../components/Header";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useEffect, useRef, useState } from "react";
import { BaseURL, contactEnquiresUrl } from "../../../constants/globalUrls";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import { callPostApi } from "../../../services/api";

const ContactEnquire = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dataFetchedRef = useRef(false);
  const [currentMode, setCurrentMode] = useState("list"); //# view ,list, edit
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState([]);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    loadService(true);
  }, []);

  const fetchRequests = async () => {
    setIsLoading(true);
    try {
      const response = await callPostApi(
        contactEnquiresUrl
      );
      // console.log("response", response.status);
      setIsLoading(false);
      if (response.status === 200) {
        // console.log("response", response.data.data);
        setResult(response.data.data);
      } else {
        toast.error("Something error occured. Try again..!");
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("An error occurred while submitting the form");
      console.log(error);
    }
  };

  const loadService = (isLoa = false) => {
    // alert(isLoa)
    if (isLoa) {
      fetchRequests();
    }
  };
  const columns = [
    { field: "id", headerName: "S.No" },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "mobile", headerName: "Mobile", flex: 1 },
    {
      field: "created_at",
      headerName: "Created At",
      flex: 1,
      valueFormatter: (params) => {
        const timestamp = params.value;
        const date = new Date(timestamp * 1000); // Multiply by 1000 to convert from seconds to milliseconds

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");

        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return formattedDate;
      },
    },
    { field: "message", headerName: "Message", flex: 1 },
  ];

  return (
    <>
      {currentMode === "list" && (
        <Box m="20px">
          <Grid container spacing={1}>
            <Grid item xs={11}>
              <Header
                title="Contact Enquires"
                subtitle="Managing the Contact Enquires List"
              />
            </Grid>
          </Grid>
          <Box
            m="0px 0 0 0"
            height="70vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
              "& .MuiCircularProgress-root": {
                color: "green",
              },
            }}
          >
            <DataGrid
              loading={isLoading}
              rows={result}
              columns={columns}
              density={"compact"}
              components={{ Toolbar: GridToolbar }}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default ContactEnquire;
