import { Box, Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import Header from "../../../components/Header";
import {
  paymentsDetailsUrl,
  paymentsSuccessUrl,
} from "../../../constants/globalUrls";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { callPostApi } from "../../../services/api";
const PaymentDetails = () => {
  const navigate = useNavigate()
  const [result, setResult] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  // payments_details
  const queryParams = new URLSearchParams(window.location.search);
  const projectId = queryParams.get("project_id");
  const transactionId = queryParams.get("payment_transaction_id");
  // console.log("projectId : " + projectId);
  // console.log("transactionId : " + transactionId);

  useEffect(() => {
    getProjectDetails();
  }, []);

  const getProjectDetails = async () => {
    await callPostApi(
        paymentsDetailsUrl,
        {
          id: projectId,
        })
      .then((res) => {
        let result = res["data"]["data"];
        // console.log("paydet", result);
        setResult(result); 
      })

      .catch((error) => {
        // setIsLoading(false);
        console.log(error);
      });
  };

  const fetchSuccessMessage = async () => {
    setIsLoading(true);
    try {
      const response = await callPostApi(
        paymentsSuccessUrl,
        { transaction_id: projectId }
      );
      // console.log("st response", response);
      setIsLoading(false);
      if (response.status === 200) {
        getProjectDetails();
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("An error occurred while submitting the form");
      console.log(error);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        paddingRight: "32px",
        paddingLeft: "32px",
        marginRight: "auto",
        marginLeft: "auto",
      }}
    >
      <Box>
        <Grid container justify="center" spacing={0}>
          <Grid item lg={8} xs={12}>
            <h2>Selected Payment Details</h2>
          </Grid>
          <Grid item lg={2} xs={12}>
          </Grid>
          <Grid 
          sx={{marginTop: "20px"}} 
          item lg={2} xs={12}>
          <Button type="button" 
          onClick={() => navigate("/payments-transactions")}
           color="info" variant="contained">
                            Back
                        </Button> &nbsp;&nbsp;
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Box
          sx={{
            color: "#FFFFFF",
            backgroundColor: "#68B90B",
            paddingTop: "8px",
            paddingBottom: "8px",
          }}
        >
          <Grid container spacing={0}>
            <Grid sx={{ paddingLeft: "10px" }} item lg={4} xs={12}>
              Title
            </Grid>
            <Grid item lg={4} xs={12}>
              Details
            </Grid>
          </Grid>
        </Box>

        <>
          <Box>
            <Grid container spacing={0}>
              <Grid sx={{ paddingLeft: "10px" }} item lg={4} xs={12}>
                <p>Payment Title</p>
              </Grid>
              <Grid item lg={3} xs={12}>
                <p>{result.package_title}</p>
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Grid container spacing={0}>
              <Grid sx={{ paddingLeft: "10px" }} item lg={4} xs={12}>
                <p>Payment Amount</p>
              </Grid>
              <Grid item lg={4} xs={12}>
                <p>{result.package_amount}</p>
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Grid container spacing={0}>
              <Grid sx={{ paddingLeft: "10px" }} item lg={4} xs={12}>
                <p>Payment Status</p>
              </Grid>
              <Grid item lg={4} xs={12}>
                <p>{result.payment_status}</p>
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Grid container spacing={0}>
              <Grid sx={{ paddingLeft: "10px" }} item lg={4} xs={12}>
                <p>No. Of Projects Allowed</p>
              </Grid>
              <Grid item lg={4} xs={12}>
                <p>{result.no_of_projects_allow}</p>
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Grid container spacing={0}>
              <Grid sx={{ paddingLeft: "10px" }} item lg={4} xs={12}>
                <p>No. Of Projects Remain</p>
              </Grid>
              <Grid item lg={4} xs={12}>
                <p>{result.no_of_projects_remain}</p>
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Grid container spacing={0}>
              <Grid sx={{ paddingLeft: "10px" }} item lg={4} xs={12}>
                <p>Air Cooled Chiller Enable</p>
              </Grid>
              <Grid item lg={4} xs={12}>
                <p>{result.air_cooled_chiller_enable}</p>
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Grid container spacing={0}>
              <Grid sx={{ paddingLeft: "10px" }} item lg={4} xs={12}>
                <p>Water Cooled Chiller Enable</p>
              </Grid>
              <Grid item lg={4} xs={12}>
                <p>{result.water_cooled_chiller_enable}</p>
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Grid container spacing={0}>
              <Grid sx={{ paddingLeft: "10px" }} item lg={4} xs={12}>
                <p>Thermal Store Enable</p>
              </Grid>
              <Grid item lg={4} xs={12}>
                <p>{result.thermal_store_enable}</p>
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Grid container spacing={0}>
              <Grid sx={{ paddingLeft: "10px" }} item lg={4} xs={12}>
                <p>TR Required</p>
              </Grid>
              <Grid item lg={4} xs={12}>
                <p>{result.tr_required}</p>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{ 
              color: "#FFFFFF",
              backgroundColor: "#68B90B",
              paddingTop: "8px",
              paddingBottom: "8px",
              //   marginBottom: "100px"
            }}
          >
            <Grid container spacing={0}>
              <Grid sx={{ paddingLeft: "10px" }} item lg={4} xs={12}>
                Total Payable Amount
              </Grid>
              <Grid item lg={4} xs={12}>
                {result.total_amount}
              </Grid>
            </Grid>
          </Box>

          <Grid sx={{ marginTop: "15px" }} item lg={4} xs={12}>
            {result.payment_status == "Pending" && (
              <Button
                onClick={() => fetchSuccessMessage()}
                variant="contained"
                color="success"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Do you want to make Transaction Success?
              </Button>
            )}
          </Grid>
        </>
      </Box>
    </Box>
  );
};

export default PaymentDetails;
