import React, { useEffect, useRef, useState } from "react";
import {
  TextField,
  Button,
  Container,
  Stack,
  Box,
  Fab,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  useTheme,
} from "@mui/material";
import {
  BaseURL,
  uploadFileOrImage,
  siteSettingsUrl,
  siteSettingsUpdateUrl,
} from "../../constants/globalUrls";
import { toast } from "react-toastify";
import Spinner from "../../components/Spinner";
import { tokens } from "../../theme";
import { callPostApi } from "../../services/api";

const UserManual = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dataFetchedRef = useRef(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [formData, setFormData] = useState({
    id: "",
    updated_at: "",
    user_manual: "",
    enable_project_json_upload: "",
    website_visit_count: "0",
  });

  const imageFileUpload = (event) => {
    const formDataImage = new FormData();
    formDataImage.append("file", event.target.files[0]);
    fetch(uploadFileOrImage, {
      method: "POST",
      body: formDataImage,
    })
      .then((r) => r.json())
      .then((data) => {
        // console.log(data["data"]["full_file_address"]);
        setFormData({
          ...formData,
          ["user_manual"]: data["data"]["full_file_address"],
        });
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    // console.log("formdata", formData);

    try {
      const response = await callPostApi(
        siteSettingsUpdateUrl,
        formData
      );
      // console.log("response", response);
      setIsLoading(false);
      if (response.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Somthing error occured..!, Please try again.");
      console.log(error);
    }
  };
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    fetchRequests();
  }, []);

  const fetchRequests = async () => {
    setIsLoading(true);
    try {
      const response = await callPostApi(
        siteSettingsUrl
      );
      // console.log("response", response.status);
      setIsLoading(false);
      if (response.status === 200) {
        // console.log("response", response.data.data);
        setFormData(response.data.data);
      } else {
        toast.error("Something error occured. Try again..!");
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("An error occurred while submitting the form");
      console.log(error);
    }
  };

  const updatedAt = new Date(formData.updated_at);
  const formattedDateTime = updatedAt.toLocaleString(undefined, {
    dateStyle: "medium",
    timeStyle: "medium",
  });

  // const formattedDate = updatedAt.toLocaleDateString();

  return (
    <div style={{ margin: "30px" }}>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <h2>User Manual</h2>
          <form method="post" onSubmit={handleSubmit}>
            <TextField
              type="text"
              variant="outlined"
              color="secondary"
              label="Updated At"
              name="updated_at"
              onChange={handleChange}
              // value={formData.updated_at}
              value={formattedDateTime}
              required
              sx={{ mb: 4, width: "90%" }}
              fullWidth
              inputProps={{ readOnly: true }}
            />
            <TextField
              type="text"
              variant="outlined"
              color="secondary"
              label="Website Visitors Count"
              onChange={handleChange}
              name="website_visit_count"
              value={formData.website_visit_count}
              required
              fullWidth
              sx={{ mb: 4, width: "90%" }}
              inputProps={{ readOnly: true }}
            />

            <FormControl sx={{ mb: 4, width: "90%" }}>
              <FormLabel id="enable-json-upload" style={{ color: colors.grey[100] }} component="legend">
                Enable JSON Data
              </FormLabel>
              <RadioGroup
                style={{ display: "flex", flexDirection: "row" }}
                aria-labelledby="enable-json-upload"
                // defaultValue="no"
                name="enable_project_json_upload"
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio onChange={handleChange} style={{ color: colors.grey[100] }} />}
                  label="yes"
                  checked={
                    formData.enable_project_json_upload === "yes"}
                />

                <FormControlLabel
                  checked={
                    formData.enable_project_json_upload === "no"}
                  value="no"
                  control={<Radio onChange={handleChange} style={{ color: colors.grey[100] }} />}
                  label="no"
                />
              </RadioGroup>
            </FormControl>

            <Stack spacing={2} direction="row">
              <TextField
                variant="outlined"
                color="secondary"
                label="Upload File"
                type="file"
                className="filestyle form-control py-1 px-2"
                name="user_manual"
                onChange={imageFileUpload}
                // required
                fullWidth
                sx={{ mb: 4, width: "90%" }}
              // inputProps={{ readOnly: true }}
              />
            </Stack>

            <Stack direction="row" sx={{ marginBottom: 4 }}>
              {formData.user_manual && (
                <>
                  <p style={{ fontWeight: "bold", color: "yellow" }}>
                    Previous File: &nbsp;
                    <a
                      style={{ color: "white" }}
                      href={`${BaseURL}${formData.user_manual}`}
                      target="_blank"
                    >
                      Click Here
                    </a>
                  </p>
                </>
              )}
            </Stack>

            <Box display="flex" justifyContent="center" mt="20px">
              <Button
                type="submit"
                sx={{
                  backgroundColor: "green",
                  "&:hover": {
                    backgroundColor: "green",
                  },
                }}
                variant="contained"
              >
                Submit
              </Button>
            </Box>
          </form>
        </>
      )}
    </div>
  );
};

export default UserManual;
