import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import DeleteIcon from '@mui/icons-material/Delete';
import Header from "../../components/Header";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useEffect, useRef, useState } from "react";
import { adminProfileDetails } from "../../constants/globalUrls";
import AddIcon from '@mui/icons-material/Add';
import { toast } from "react-toastify";
import FormData from "./components/form_component";
import { callPostApi } from "../../services/api"
import { Box, Autocomplete, Grid, Button, useTheme, TextField, TextareaAutosize, FormHelperText, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { Field, Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { addUpdateStaffUrl } from "../../constants/globalUrls";


export const ProfileEdit = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dataFetchedRef = useRef(false);
    const [isLoading, setIsLoading] = useState(false)
    const [result, setResult] = useState({
        "id": "",
        "access_token": "",
        "email": "",
        "last_login_time": "",
        "mobile": "",
        "name": "",
        "role_id": 1
    })
    const initialValues = {
        name: '',
        email: '',
        mobile: '',
        password: '',
    }
    const isNonMobile = useMediaQuery("(min-width:600px)");

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        fetchRequests();
    }, [])

    const fetchRequests = async () => {
        setIsLoading(true);
        try {
            const response = await callPostApi(
                adminProfileDetails
            );
            // console.log("response", response.status);
            setIsLoading(false);
            if (response.status === 200) {
                // console.log("response", response.data.data)
                setResult(response.data.data);
                initialValues.name = response.data.data.name
                initialValues.email = response.data.data.email
                initialValues.id = response.data.data._id
                initialValues.mobile = response.data.data.mobile
            } else {
                toast.error("Something error occured. Try again..!");
            }
        } catch (error) {
            setIsLoading(false);
            toast.error("An error occurred while submitting the form...");
            console.log(error);
        }
    };




    const handleFormSubmit = async (postData) => {
        // console.log(postData)
        // return false
        let id = toast.loading("Please wait...", { theme: "dark" })
        const response = await callPostApi(addUpdateStaffUrl, postData);
        const responseData = response?.data;
        if (responseData.status === 'valid') {
            // toast.success(responseData.message)
            toast.update(id, { render: "Profile updated successfully", autoClose: "3000", theme: "dark", type: "success", isLoading: false });
        } else {
            toast.update(id, { render: responseData.message, autoClose: "3000", theme: "dark", type: "error", isLoading: false });
        }
        // console.log(responseData.message);
    };

    const checkoutSchema = yup.object().shape({
        name: yup.string().required("required"),
        email: yup.string().required("required"),
        mobile: yup.string().required("required"),

    });

    return (
        <>
            {/* <pre style={{ color: "#fff" }}>
                {JSON.stringify(result, null, 2)}
            </pre> */}
            <Box m="20px">
                <Grid container spacing={2}>
                    <Grid item xs={7}>
                        <Header title="Update Profile" subtitle="Details Update Page" />
                    </Grid>
                    <Grid item xs={5}>
                    </Grid>
                </Grid>
                <Box
                    m="0px 0 0 0"
                    height="70vh"
                >
                    {result ? (<Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={checkoutSchema}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <Box
                                    display="grid"
                                    gap="30px"
                                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                    sx={{
                                        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                    }}
                                >
                                    <input type="hidden" name="id" value={values._id} />
                                    <TextField
                                        //         InputLabelProps={{
                                        //     style: { color: "white" }
                                        // }}
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.name}
                                        name="name"
                                        error={!!touched.name && !!errors.name}
                                        helperText={touched.name && errors.name}
                                        sx={{ gridColumn: "span 4" }}
                                    />
                                    <TextField
                                        //          InputLabelProps={{
                                        //     style: { color: "white" }
                                        // }}
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.email}
                                        name="email"
                                        error={!!touched.email && !!errors.email}
                                        helperText={touched.email && errors.email}
                                        sx={{ gridColumn: "span 4" }}
                                    />
                                    <TextField
                                        //          InputLabelProps={{
                                        //     style: { color: "white" }
                                        // }}
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Mobile"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.mobile}
                                        name="mobile"
                                        error={!!touched.mobile && !!errors.mobile}
                                        helperText={touched.mobile && errors.mobile}
                                        sx={{ gridColumn: "span 4" }}
                                    />
                                    <TextField
                                        //          InputLabelProps={{
                                        //     style: { color: "white" }
                                        // }}
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="New Password"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.password}
                                        name="password"
                                        helperText={"Note : Leave empty if not required"}
                                        sx={{ gridColumn: "span 4" }}
                                    />
                                    {/* //image */}


                                </Box>
                                <Box display="flex" justifyContent="center" mt="20px">

                                    <Button type="submit" color="secondary" variant="contained">
                                        Submit
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                    ) : (
                        <div>Loading...</div>
                    )}
                </Box>
            </Box>

        </>


    );
}
