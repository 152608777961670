import {
  Box,
  Button,
  useTheme,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { Field, Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { callPostApi } from "../../../services/api";
import { toast } from "react-toastify";
import { useEffect, useRef, useState } from "react";
import { tokens } from "../../../theme";
import {paymentSettingsAddUpdateUrl } from "../../../constants/globalUrls";

const PaymentSettingsForm = (props) => {
  const theme = useTheme();
  const dataFetchedRef = useRef(false);
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { currentObj, modeSetUp, loadService, editPermission } = props;
  const initialValues = {
    id: currentObj._id,
    payment_gateway_name: currentObj.payment_gateway_name,
    profile_id: currentObj.profile_id,
    mode: currentObj.mode,
    security_key: currentObj.security_key,
    access_key: currentObj.access_key,
  };

  const handleFormSubmit = async (postData) => {
    // return false
    let id = toast.loading("Please wait...", { theme: "dark" });
    const response = await callPostApi(
        paymentSettingsAddUpdateUrl,
      postData
    );
    const responseData = response?.data;
    // console.log("submitted gateway data", responseData)
    if (responseData.status === "valid") {
      // toast.success(responseData.message)
      toast.update(id, {
        render: responseData.message,
        autoClose: "3000",
        theme: "dark",
        type: "success",
        isLoading: false,
      });
      loadService(true);
      modeSetUp("list");
    } else {
      toast.update(id, {
        render: responseData.message,
        autoClose: "3000",
        theme: "dark",
        type: "error",
        isLoading: false,
      });
    }
    // console.log(responseData.message);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={checkoutSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <input type="hidden" value={initialValues._id} />
            <TextField
              InputLabelProps={{
                style: { color: "white" },
              }}
              fullWidth
              variant="filled"
              type="text"
              label="Payment Gateway Name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.payment_gateway_name}
              name="payment_gateway_name"
              error={
                !!touched.payment_gateway_name && !!errors.payment_gateway_name
              }
              helperText={
                touched.payment_gateway_name && errors.payment_gateway_name
              }
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              InputLabelProps={{
                style: { color: "white" },
              }}
              fullWidth
              variant="filled"
              type="text"
              label="Profile ID"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.profile_id}
              name="profile_id"
              error={!!touched.profile_id && !!errors.profile_id}
              helperText={touched.profile_id && errors.profile_id}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              InputLabelProps={{
                style: { color: "white" },
              }}
              fullWidth
              variant="filled"
              type="text"
              label="Security Key"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.security_key}
              name="security_key"
              error={!!touched.security_key && !!errors.security_key}
              helperText={touched.security_key && errors.security_key}
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              InputLabelProps={{
                style: { color: "white" },
              }}
              fullWidth
              variant="filled"
              type="text"
              label="Access Key"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.access_key}
              name="access_key"
              error={!!touched.access_key && !!errors.access_key}
              helperText={touched.access_key && errors.access_key}
              sx={{ gridColumn: "span 2" }}
            />

            <FormControl component="fieldset" sx={{ gridColumn: "span 4" }}>
              <FormLabel style={{ color: colors.grey[100] }} component="legend">
                Mode
              </FormLabel>
              <Field name="mode" sx={{ gridColumn: "span 2" }}>
                {({ field }) => (
                  <RadioGroup
                    {...field}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <FormControlLabel
                      value="Live"
                      control={<Radio style={{ color: colors.grey[100] }} />}
                      label="Live"
                    />
                    <FormControlLabel
                      value="Test"
                      control={<Radio style={{ color: colors.grey[100] }} />}
                      label="Test"
                    />
                  </RadioGroup>
                )}
              </Field>
            </FormControl>
          </Box>
          <Box display="flex" justifyContent="center" mt="20px">
            <Button
              type="button"
              onClick={() => modeSetUp("list")}
              color="info"
              variant="contained"
            >
              Back
            </Button>{" "}
            &nbsp;&nbsp;
            {/* <Button type="submit" color="secondary" variant="contained">
                            Submit   
                        </Button> */}
            {editPermission && (
              <>
                <Button type="submit" color="secondary" variant="contained">
                  Submit
                </Button>
              </>
            )}
          </Box>
        </form>
      )}
    </Formik>
  );
};

const checkoutSchema = yup.object().shape({
  payment_gateway_name: yup.string().required("required"),
  security_key: yup.string().required("required"),
  access_key: yup.string().required("required"),
  profile_id: yup.string().required("required"),
  mode: yup.string().required("required"),
});

export default PaymentSettingsForm;
