import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import TerrainIcon from "@mui/icons-material/Terrain";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import VrpanoIcon from "@mui/icons-material/Vrpano";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PaymentsIcon from "@mui/icons-material/Payments";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import RedeemIcon from "@mui/icons-material/Redeem";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import logo from "../../assets/images/logo.png";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import RateReviewIcon from "@mui/icons-material/RateReview";
import { checkPermission } from "../../constants/helpers";
import {
  ContactMail,
  GraphicEq,
  Logout,
  MarkEmailRead,
} from "@mui/icons-material";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  <img src={logo} width="100%" alt="logo" />
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {/* {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={`../../assets/user.png`}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  Ed Roh
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]}>
                  VP Fancy Admin
                </Typography>
              </Box>
            </Box>
          )} */}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            {checkPermission("64719e469f480d672be519a9", "view_permission") && (
              <>
                <Item
                  title="Dashboard"
                  to="/dashboard"
                  icon={<HomeOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </>
            )}

            {checkPermission("64719ee36a5e2082a24c083a", "view_permission") && (
              <>
                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  sx={{ m: "15px 0 5px 20px" }}
                >
                  Locations
                </Typography>
                {checkPermission(
                  "64719f0c6a5e2082a24c083b",
                  "view_permission"
                ) && (
                  <>
                    <Item
                      title="Countries"
                      to="/countries"
                      icon={<TerrainIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </>
                )}
                {checkPermission(
                  "64719f116a5e2082a24c083c",
                  "view_permission"
                ) && (
                  <>
                    <Item
                      title="locations"
                      to="/locations"
                      icon={<VrpanoIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </>
                )}
                {checkPermission(
                  "64e32ffcc5650ceb4f81374d",
                  "view_permission"
                ) && (
                  <>
                    <Item
                      title="Weather Data"
                      to="/weather"
                      icon={<GraphicEq />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </>
                )}
              </>
            )}

            {checkPermission("64719f346a5e2082a24c083d", "view_permission") && (
              <>
                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  sx={{ m: "15px 0 5px 20px" }}
                >
                  Customers
                </Typography>
                {checkPermission(
                  "64719f416a5e2082a24c083e",
                  "view_permission"
                ) && (
                  <>
                    <Item
                      title="Manage Customers"
                      to="/customers"
                      icon={<PeopleOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </>
                )}
              </>
            )}

            {checkPermission("64719f5d6a5e2082a24c083f", "view_permission") && (
              <>
                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  sx={{ m: "15px 0 5px 20px" }}
                >
                  Projects
                </Typography>
                {checkPermission(
                  "64719f676a5e2082a24c0840",
                  "view_permission"
                ) && (
                  <>
                    <Item
                      title="Manage Projects"
                      to="/projects"
                      icon={<SettingsSuggestIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </>
                )}
              </>
            )}

            {checkPermission("64719f7f6a5e2082a24c0841", "view_permission") && (
              <>
                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  sx={{ m: "15px 0 5px 20px" }}
                >
                  Subscriptions
                </Typography>
                {checkPermission(
                  "64719f986a5e2082a24c0842",
                  "view_permission"
                ) && (
                  <>
                    <Item
                      title="Package Categories"
                      to="/packages-categories"
                      icon={<RedeemIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </>
                )}
                {checkPermission(
                  "64719f9e6a5e2082a24c0843",
                  "view_permission"
                ) && (
                  <>
                    <Item
                      title="Package Sub Categories"
                      to="/packages-sub-categories"
                      icon={<RedeemIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </>
                )}

                {checkPermission(
                  "64719faa6a5e2082a24c0844",
                  "view_permission"
                ) && (
                  <>
                    <Item
                      title="All Packages"
                      to="/packages"
                      icon={<RedeemIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </>
                )}

                {/* {checkPermission( "64719fdb6a5e2082a24c0845", "view_permission") && <>
              <Item
                title="Manage Subscriptions"
                to="/subscription-users"
                icon={<ManageAccountsIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            </>} */}
              </>
            )}

            {checkPermission("6471a01b6a5e2082a24c0846", "view_permission") && (
              <>
                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  sx={{ m: "15px 0 5px 20px" }}
                >
                  Payments
                </Typography>
                {checkPermission(
                  "6471a0306a5e2082a24c0847",
                  "view_permission"
                ) && (
                  <>
                    <Item
                      title="Manage Transactions"
                      to="/payments-transactions"
                      icon={<AttachMoneyIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </>
                )}
                {checkPermission(
                  "6471a0466a5e2082a24c0848",
                  "view_permission"
                ) && (
                  <>
                    <Item
                      title="Reports & Statistics"
                      to="/reports"
                      icon={<PaymentsIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </>
                )}
              </>
            )}

            {/* <Item
                      title="Payment Settings"
                      to="/paymentgateway-settings"
                      icon={<AttachMoneyIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    /> */}

            {checkPermission("6471a0566a5e2082a24c0849", "view_permission") && (
              <>
                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  sx={{ m: "15px 0 5px 20px" }}
                >
                  Staff
                </Typography>
                {checkPermission(
                  "6471a0666a5e2082a24c084a",
                  "view_permission"
                ) && (
                  <>
                    <Item
                      title="Manage Staff"
                      to="/staff"
                      icon={<PeopleOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </>
                )}
                {checkPermission(
                  "6471a0726a5e2082a24c084b",
                  "view_permission"
                ) && (
                  <>
                    <Item
                      title="Roles"
                      to="/roles"
                      icon={<ContactsOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </>
                )}
              </>
            )}

            {checkPermission("64e33193c5650ceb4f813750", "view_permission") && (
              <>
                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  sx={{ m: "15px 0 5px 20px" }}
                >
                  Landing Page
                  {/* {(checkPermission("64e33193c5650ceb4f813750", "view_permission"))? "T":"F"}  */}
                </Typography>

                {checkPermission(
                  "64e33347c5650ceb4f813759",
                  "view_permission"
                ) && (
                  <>
                    <Item
                      title="Landingpage Data"
                      to="/landingpagecontent"
                      icon={<PeopleOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </>
                )}
                {checkPermission(
                  "64e33244c5650ceb4f813752",
                  "view_permission"
                ) && (
                  <Item
                    title="Contact Enquires"
                    to="/contactenquires"
                    icon={<ContactMail />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                )}
                {checkPermission(
                  "64e3325fc5650ceb4f813754",
                  "view_permission"
                ) && (
                  <Item
                    title="Newsletter"
                    to="/newsletter"
                    // icon={<MailOutline />}
                    icon={<MarkEmailRead />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                )}
                {checkPermission(
                  "64e33268c5650ceb4f813756",
                  "view_permission"
                ) && (
                  <>
                    <Item
                      title="Projects"
                      to="/landingpage_projects"
                      icon={<PeopleOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </>
                )}
                {checkPermission(
                  "64e33271c5650ceb4f813758",
                  "view_permission"
                ) && (
                  <>
                    <Item
                      title="Features"
                      to="/landingpage_features"
                      icon={<ContactsOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </>
                )}
              </>
            )}
            {checkPermission("6471a08b6a5e2082a24c084c", "view_permission") && (
              <>
                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  sx={{ m: "15px 0 5px 20px" }}
                >
                  Pages
                </Typography>

                {checkPermission(
                  "6471a0986a5e2082a24c084d",
                  "view_permission"
                ) && (
                  <>
                    <Item
                      title="CMS Pages"
                      to="/cms"
                      icon={<AutoStoriesIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </>
                )}
                {checkPermission(
                  "6471a0a56a5e2082a24c084e",
                  "view_permission"
                ) && (
                  <>
                    <Item
                      title="User Manual"
                      to="/usermanual"
                      icon={<AutoStoriesIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </>
                )}
                {checkPermission(
                  "6471a0b26a5e2082a24c084f",
                  "view_permission"
                ) && (
                  <>
                    <Item
                      title="Help & Support"
                      to="/support"
                      icon={<RateReviewIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </>
                )}
                {checkPermission(
                  "6471a0bc6a5e2082a24c0850",
                  "view_permission"
                ) && (
                  <>
                    <Item
                      title="Feedbacks"
                      to="/feedbacks"
                      icon={<QuestionAnswerIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </>
                )}
                {checkPermission(
                  "6471a0d96a5e2082a24c0851",
                  "view_permission"
                ) && (
                  <>
                    <Item
                      title="Profile Form"
                      to="/profile"
                      icon={<PersonOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </>
                )}

                {/* {checkPermission(
                  "6471a0ee6a5e2082a24c0852",
                  "view_permission"
                ) && (
                  <>
                    <Item
                      title="FAQ Page"
                      to="/faq"
                      icon={<HelpOutlineOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </>
                )} */}
              </>
            )}

            <Item
              title="Logout"
              to="/logout"
              icon={<Logout />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
