
import axios from 'axios';
import { toast } from 'react-toastify';
export const callPostApi = async (url, postData={}, headers = { "Content-Type": "application/json" }) => {
  try {
    let urlData = postData;
    // if (!urlData?.access_token) {
      urlData.access_token = localStorage.getItem("access_token");
    // }
    const response = await axios.post(url, urlData, { headers });
    if (response.status === 200) {
      return response;
    } else {
      // toast.error("Data fetching failed.Try after sometime");
      return false;
    }
  } catch (error) {
    console.log(error);
    // toast.error("An error occurred while submitting the form.");
  }
}

export const callGetApi = async (url, getParamsData={},  headers = { "Content-Type": "application/json" }) => {
  try {
    let urlData = getParamsData;
    if (!urlData?.access_token) {
      urlData.access_token = localStorage.getItem("access_token"); 
    }
    const response = await axios.get(url, urlData, { headers });
    return response.data;
  } catch (error) {
    console.log(error);
    // toast.error("An error occurred while submitting the form.");
  }
}