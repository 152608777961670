import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  Link,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { DataGrid, GridSearchIcon, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import DeleteIcon from "@mui/icons-material/Delete";
import Header from "../../components/Header";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useEffect, useState, useRef } from "react";
import {
  allPackagesListUrl,
  customersDeleteUrl,
  customersListUrl,
  deleteProjectListUrl,
  projectListUrl,
  titlePackagesListUrl,
} from "../../constants/globalUrls";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import LoopIcon from "@mui/icons-material/Loop";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";
import { useNavigate } from "react-router-dom";
import BarChart from "../../components/BarChart";
import BarCharts from "../../components/barcharts";
import { callPostApi } from "../../services/api";
const Chart = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dataFetchedRef = useRef(false);
  const [result, setResult] = useState([]);
  const [resultTitle, setTitleResult] = useState([]);
  const [resultCategory, setResultCategory] = useState([]);
  const [resultSubCategory, setResultSubCategory] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [currentStatus, setcurrentStatus] = useState("");
  const [formData, setFormdata] = useState({
    from_date: "",
    to_date: "",
    current_stauts: "",
    search_key: "",
    access_token: localStorage.getItem("access_token"),
  });

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    loadService(true);
  }, []);

  const fetchRequests = async (title = "", sub_title = "") => {
    try {
      const response = await callPostApi(
        allPackagesListUrl,
        {
          title: title,
          sub_title: sub_title,
        }
      );
      // console.log("response", response.status);
      if (response.status === 200) {
        // console.log("response ch", response.data.data);
        // console.log("response cate", response.data.data);
        setResult(response.data.data);
        setResultCategory(response.data.data.category)
      } else {
        toast.error("Something error occured. Try again..!");
      }
    } catch (error) {
      toast.error("An error occurred while submitting the form");
      console.log(error);
    }
  };

  const fetchMainPackage = async () => {
    try {
      const response = await callPostApi(
        titlePackagesListUrl
      );
      // console.log("response", response.status);
      if (response.status === 200) {
        // console.log("response char", response.data.data);
        let listItems = [];
        if (response.data.data !== "") {
          response.data.data.map((item, index) => {
            // console.log(item)
            listItems.push({
              year: index + 1,
              label: item.title,
            });
          });

          setTitleResult(listItems);
        }
      } else {
        toast.error("Something error occured. Try again..!");
      }
    } catch (error) {
      toast.error("An error occurred while submitting the form");
      console.log(error);
    }
  };

  const changeMainPackage = (event) => {
    fetchRequests(event.target.innerHTML);
  };

  const loadService = (isLoa = false) => {
    if (isLoa) {
      fetchRequests();
      fetchMainPackage();
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // alert(name+" "+ value)
    setFormdata({ ...formData, [name]: value });
    // console.log(formData);
  };
  return (
    <>
      <Box m="20px">
        <Grid container justify="center" spacing={0}>
          <Grid item lg={2} xs={12}>
            <Header title="Bar Charts" subtitle="Managing the Bar Charts" />
          </Grid>
          <Grid item lg={2} xs={12}>
            <InputLabel htmlFor="status">From Date</InputLabel>
            <TextField
              InputLabelProps={{
                style: { color: "white" },
              }}
              type="date"
              fullWidth
              sx={{ gridColumn: "span 2" }}
              name="from_date"
              value={formData.from_date.slice(0, 10)} // convert to ISO-8601 string
              onChange={handleInputChange} // update state with string value
            />
          </Grid>
          <Grid item lg={2} xs={12}>
            <InputLabel htmlFor="status">To Date</InputLabel>
            <TextField
              InputLabelProps={{
                style: { color: "white" },
              }}
              type="date"
              fullWidth
              sx={{ gridColumn: "span 2" }}
              name="to_date"
              value={formData.to_date.slice(0, 10)} // convert to ISO-8601 string
              onChange={handleInputChange} // update state with string value
            />
          </Grid>
          <Grid item lg={2} xs={12}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              onChange={changeMainPackage}
              options={resultTitle}
              //   sx={{ width: 300 }}
              sx={{ width: 200, marginTop: "20px" }}
              renderInput={(params) => (
                <TextField
                  InputLabelProps={{
                    style: { color: "white" },
                  }}
                  {...params}
                  label="Select Package"
                />
              )}
            />
          </Grid>
          <Grid item lg={2} xs={12}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              //   onChange={}
              options={result}
              sx={{ width: 200, marginTop: "20px" }}
              renderInput={(params) => (
                <TextField
                  InputLabelProps={{
                    style: { color: "white" },
                  }}
                  {...params}
                  label="Select Subcategories"
                />
              )}
            />
          </Grid>
          <Grid item lg={2} xs={12} mt={1.6} p={1}>
            <Button
              style={{ padding: "14px  10px", margin: "0px" }}
              onClick={() => loadService(true)}
              variant="contained"
              color="success"
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              Submit&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </Button>
          </Grid>
        </Grid>

        <Box
          m="0px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.redAccent} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
            "& .MuiCircularProgress-root": {
              color: "green",
            },
          }}
        >
          <BarCharts />
        </Box>
      </Box>
    </>
  );
};

export default Chart;
