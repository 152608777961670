import { Box, Button, Grid, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useEffect, useRef, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import { callPostApi } from "../../services/api";
import {
  deletepaymentSettingsUrl,
  paymentSettingsListUrl,
} from "../../constants/globalUrls";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import PaymentSettingsForm from "./components/form_component";

const GatewaySettings = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dataFetchedRef = useRef(false);
  const [currentMode, setCurrentMode] = useState("list"); //# view ,list, edit
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState([]);
  const [currentObj, setCurrentObj] = useState({
    id: "",
    _id: "",
    payment_gateway_name: "",
    profile_id: "",
    mode: "live",
    security_key: "",
    access_key: "",
  });

  const permissions = Object.freeze({
    add: true,
    edit: true,
    view: true, // List of data must be true for other permissions
    delete: true,
  });

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    // fetchRequests();
    loadService(true);
  }, []);
  const deleteRecordNow = async (rowId) => {
    let id = toast.loading("Please wait...");
    const response = await callPostApi(deletepaymentSettingsUrl, {
      id: rowId,
    });
    const responseData = response?.data;
    if (responseData.status === "valid") {
      // toast.success(responseData.message)
      toast.update(id, {
        render: responseData.message,
        autoClose: "3000",
        type: "success",
        isLoading: false,
      });
      loadService(true);
    } else {
      toast.error(responseData.message);
    }
    // console.log(responseData.message);
  };

  const fetchRequests = async () => {
    setIsLoading(true);
    try {
      const response = await callPostApi(
        paymentSettingsListUrl
      );
      // console.log("p gresponse", response);
      setIsLoading(false);
      if (response.status === 200) {
        // console.log("payment gateway response", response.data.data);
        setResult(response.data.data);
      } else {
        toast.error("Something error occured. Try again..!");
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("An error occurred while submitting the form");
      console.log(error);
    }
  };

  const editFunClick = (data) => {
    // console.log(data);
    setCurrentObj(data);
    setCurrentMode("edit");
  };
  const loadService = (isLoa = false) => {
    // alert(isLoa)
    if (isLoa) {
      fetchRequests();
    }
  };
  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "payment_gateway_name",
      headerName: "Payment Gateway Name",
      flex: 2,
    },
    { field: "profile_id", headerName: "Profile ID", flex: 1 },
    { field: "mode", headerName: "Mode", flex: 1 },
    { field: "security_key", headerName: "Security Key", flex: 1 },
    { field: "access_key", headerName: "Access Key", flex: 1 },
    {
      field: "created_at",
      headerName: "Created At",
      flex: 1,
      valueFormatter: (params) => {
        const timestamp = params.value;
        const date = new Date(timestamp * 1000); // Multiply by 1000 to convert from seconds to milliseconds

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");

        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return formattedDate;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      headerAlign: "center",
      align: "center",
      flex: 2,
      renderCell: (params) => {
        return (
          <>
            {permissions.edit && (
              <>
                <Button
                  onClick={() => editFunClick(params.row)}
                  variant="contained"
                  color="success"
                >
                  <BorderColorIcon />
                </Button>
              </>
            )}
            &nbsp;&nbsp;
            {permissions.delete && (
              <>
                <Button
                  onClick={() => {
                    if (
                      window.confirm(
                        'Are you sure Delete this "' +
                        params.row.payment_gateway_name +
                        '" Payment Gateway Setting?'
                      )
                    ) {
                      deleteRecordNow(params.row._id);
                    }
                  }}
                  variant="contained"
                  color="error"
                >
                  <DeleteIcon />
                </Button>
              </>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      {(currentMode === "edit" || currentMode === "view") && (
        <>
          <Box m="20px">
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Header
                  title="Add / Edit payment Gateway Settings"
                  subtitle="View / Edit the payment Gateway Settings "
                />
              </Grid>
              <Grid item xs={5}></Grid>
            </Grid>
            <Box m="0px 0 0 0" height="70vh">
              <PaymentSettingsForm
                editPermission={permissions.edit}
                loadService={loadService}
                currentObj={currentObj}
                modeSetUp={setCurrentMode}
              />
            </Box>
          </Box>
        </>
      )}

      {currentMode === "list" && (
        <Box m="20px">
          <Grid container spacing={1}>
            <Grid item xs={11}>
              <Header
                title="Payment Gateway Settings"
                subtitle="Managing the Payment Gateway Settings"
              />
            </Grid>
            <Grid item xs={1}>
              {permissions.add && (
                <>
                  <Button
                    onClick={() => {
                      setCurrentMode("view");
                      setCurrentObj({});
                    }}
                    startIcon={<AddIcon sx={{ color: theme.palette.text.primary }} />}
                    variant="outlined"
                    color="success"
                  >
                    <span style={{ color: theme.palette.text.primary }}>Add</span>
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
          <Box
            m="0px 0 0 0"
            height="70vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
              "& .MuiCircularProgress-root": {
                color: "green",
              },
            }}
          >
            <DataGrid
              loading={isLoading}
              rows={result}
              columns={columns}
              density={"compact"}
              components={{ Toolbar: GridToolbar }}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default GatewaySettings;
